import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGolfBallTee, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { isAuthenticated, logoutUser } from "../api";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(isAuthenticated());
  const navigate = useNavigate();

  useEffect(() => {
    setLoggedIn(isAuthenticated());
  }, []);

  const handleLogout = () => {
    logoutUser();
    setLoggedIn(false);
    navigate("/");
  };

  return (
    <nav className="bg-green-700 text-white px-6 py-4 shadow-lg fixed top-0 left-0 w-full z-50">
      <div className="max-w-6xl mx-auto flex justify-between items-center">

        {/* Logo */}
        <Link to="/" className="text-2xl font-bold flex items-center">
          <FontAwesomeIcon icon={faGolfBallTee} className="mr-2 text-yellow-400" />
          GreenFees
        </Link>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-6">
          <Link to="/" className="hover:text-yellow-400 transition-all">Home</Link>
          <Link to="/about" className="hover:text-yellow-400 transition-all">About</Link>
          <Link to="/contact" className="hover:text-yellow-400 transition-all">Contact</Link>

          {loggedIn ? (
            <>
              <Link to="/add-course" className="bg-yellow-400 text-green-800 px-4 py-2 rounded shadow hover:bg-yellow-500 transition-all">
                + Add Course
              </Link>
              <button onClick={handleLogout} className="ml-4 bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600">
                Logout
              </button>
            </>
          ) : (
            <Link to="/login" className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600">
              Login
            </Link>
          )}
        </div>

        {/* Mobile Menu Button */}
        <button className="md:hidden text-white text-2xl" onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden mt-2 bg-green-800 p-4 rounded-lg absolute top-full left-0 w-full">
          <Link to="/" className="block py-3 text-lg hover:text-yellow-400" onClick={() => setIsOpen(false)}>Home</Link>
          <Link to="/about" className="block py-3 text-lg hover:text-yellow-400" onClick={() => setIsOpen(false)}>About</Link>
          <Link to="/about" className="block py-3 text-lg hover:text-yellow-400" onClick={() => setIsOpen(false)}>Contact</Link>

          {loggedIn ? (
            <>
              <Link to="/add-course" className="block py-3 text-lg bg-yellow-400 text-green-800 px-4 py-2 rounded shadow hover:bg-yellow-500 transition-all text-center" onClick={() => setIsOpen(false)}>+ Add Course</Link>
              <button onClick={handleLogout} className="w-full mt-2 bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600 text-center">
                Logout
              </button>
            </>
          ) : (
            <Link to="/login" className="block py-3 text-lg bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 text-center" onClick={() => setIsOpen(false)}>
              Login
            </Link>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;

