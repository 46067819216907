import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Login from "./pages/Login";
import AddCourse from "./pages/AddCourse";
import CourseDetail from "./pages/CourseDetail";
import Contact from "./pages/Contact";

function App() {
    const [token, setToken] = useState(localStorage.getItem("token"));

    useEffect(() => {
        if (token) {
            localStorage.setItem("token", token);
        } else {
            localStorage.removeItem("token");
        }
    }, [token]);

    return (
        <Router>
            <Navbar token={token} setToken={setToken} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/login" element={<Login setToken={setToken} />} />
                <Route path="/courses/:slug" element={<CourseDetail />} />
                <Route 
                    path="/add-course" 
                    element={token ? <AddCourse /> : <Navigate to="/login" />} 
                />
	        <Route path="/contact" element={<Contact />} />
            </Routes>
        </Router>
    );
}

export default App;

