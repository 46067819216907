// Set default API URL
const API_URL = process.env.REACT_APP_API_URL || "https://api.greenfees.co.za";

// Store & retrieve authentication token
const getToken = () => localStorage.getItem("token");
const setToken = (token) => localStorage.setItem("token", token);
const removeToken = () => localStorage.removeItem("token");

// Register a new user
export const registerUser = async (email, password) => {
  const response = await fetch(`${API_URL}/auth/register`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  });
  return response.json();
};

// Log in & store token
export const loginUser = async (email, password) => {
  const response = await fetch(`${API_URL}/auth/jwt/login`, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams({ username: email, password }),
  });
  const data = await response.json();
  if (data.access_token) setToken(data.access_token);
  return data;
};

// Log out user
export const logoutUser = () => {
  removeToken();
};

// Check if user is authenticated
export const isAuthenticated = () => !!getToken();

// Add a new course (requires authentication)
export const addCourse = async (courseData) => {
  const response = await fetch(`${API_URL}/courses/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(courseData),
  });
  return response.json();
};

// Get all courses
export const getCourses = async () => {
  const response = await fetch(`${API_URL}/courses`);
  return response.json();
};

// Get a single course
export const getCourse = async (slug) => {
  const response = await fetch(`${API_URL}/courses/${slug}`);
  if (!response.ok) {
    throw new Error("Course not found");
  }
  return response.json();
};

// Export API_URL so it can be used elsewhere
// export { API_URL };

// Add a new course
/*
export const addCourse = async (courseData) => {
  const response = await fetch(`${API_URL}/courses/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      name: courseData.name,
      email: courseData.email,
      fees: courseData.fees,
    }),
  });
  return response.json();
};
*/

