import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addCourse, isAuthenticated } from "../api";

const AddCourse = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState({
    name: "",
    email: "",
    fees: { member: "", affiliated: "", non_affiliated: "", visitor: "" },
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name in course.fees) {
      setCourse((prev) => ({ ...prev, fees: { ...prev.fees, [name]: value } }));
    } else {
      setCourse((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!course.name || !course.email || Object.values(course.fees).some(fee => !fee)) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }

    try {
      await addCourse(course);
      setSuccess(true);
      setTimeout(() => navigate("/"), 2000);
    } catch (err) {
      setError("Failed to add course. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-green-600 to-green-900 text-white p-6">
      <div className="bg-white text-gray-900 p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-green-700 text-center">Add a New Course</h2>

        {error && <p className="text-red-600 text-center">{error}</p>}
        {success && <p className="text-green-600 text-center">Course added successfully! Redirecting...</p>}

        <form onSubmit={handleSubmit} className="space-y-4">
          <input type="text" name="name" placeholder="Course Name" value={course.name} onChange={handleChange} className="w-full p-2 border rounded" required />
          <input type="email" name="email" placeholder="Course Email" value={course.email} onChange={handleChange} className="w-full p-2 border rounded" required />

          <h3 className="text-lg font-semibold mt-2">Green Fees</h3>
          <input type="text" name="member" placeholder="Member Fee" value={course.fees.member} onChange={handleChange} className="w-full p-2 border rounded" required />
          <input type="text" name="affiliated" placeholder="Affiliated Fee" value={course.fees.affiliated} onChange={handleChange} className="w-full p-2 border rounded" required />
          <input type="text" name="non_affiliated" placeholder="Non-Affiliated Fee" value={course.fees.non_affiliated} onChange={handleChange} className="w-full p-2 border rounded" required />
          <input type="text" name="visitor" placeholder="Visitor Fee" value={course.fees.visitor} onChange={handleChange} className="w-full p-2 border rounded" required />

          <button type="submit" className={`w-full ${loading ? "bg-gray-400" : "bg-green-700"} text-white p-3 rounded-md hover:bg-green-800`} disabled={loading}>
            {loading ? "Adding..." : "Add Course"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddCourse;

