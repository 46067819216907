import React, { useState, useEffect } from "react";
import { getCourse } from "../api";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faGolfBallTee } from "@fortawesome/free-solid-svg-icons";

const CourseDetail = () => {
  const { slug } = useParams();
  console.log("Retrieved slug:", slug);
  const [course, setCourse] = useState(null);

  useEffect(() => {
    console.log("Attempting to fetch course with slug:", slug);
    getCourse(slug)
	.then(setCourse)
	.catch((error) => {
	  console.error("Error fetching course:", error);
	});
  }, [slug]);

  if (!course) {
    return <p className="text-center text-gray-700">Loading...</p>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-green-600 to-green-900 text-white p-6">
      <div className="bg-white text-gray-900 p-6 rounded-lg shadow-lg w-full max-w-lg text-center md:text-left">
    
        {/* Course Name */}
        <h2 className="text-2xl md:text-3xl font-bold mb-4 text-green-700">
          <FontAwesomeIcon icon={faGolfBallTee} className="mr-2 text-green-500" />
          {course.name}
        </h2>

        {/* Email */}
        <p className="text-gray-600 mb-4 flex items-center justify-center md:justify-start">
          <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-blue-500" />
          <a href={`mailto:${course.email}`} className="text-blue-600 hover:underline">
            {course.email}
          </a>
        </p>

        {/* Green Fees */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center md:text-left">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Green Fees</h3>
          <ul className="space-y-2">
            <li className="py-2 flex justify-between">
              <span className="text-gray-600">Member Rate:</span>
              <span className="font-bold text-green-600">{course.fees.member}</span>
            </li>
            <li className="py-2 flex justify-between">
              <span className="text-gray-600">Affiliated Rate:</span>
              <span className="font-bold text-green-600">{course.fees.affiliated}</span>
            </li>
            <li className="py-2 flex justify-between">
              <span className="text-gray-600">Non-Affiliated Rate:</span>
              <span className="font-bold text-green-600">{course.fees.non_affiliated}</span>
            </li>
            <li className="py-2 flex justify-between">
              <span className="text-gray-600">Visitor Rate:</span>
              <span className="font-bold text-green-600">{course.fees.visitor}</span>
            </li>
          </ul>
        </div>

        {/* Back Button */}
        <Link
          to="/"
          className="mt-6 inline-block bg-yellow-400 text-gray-900 px-6 py-3 rounded-md hover:bg-yellow-500 transition-all text-lg"
        >
          ← Back to Home
        </Link>
      </div>
    </div>

    );
  };

export default CourseDetail;

