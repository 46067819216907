import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faGolfBallTee, faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

const About = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-green-600 to-green-900 text-white p-6">
      <div className="bg-white text-gray-900 p-6 rounded-lg shadow-lg w-full max-w-lg text-center">
        
        {/* Profile Picture */}
        <img 
          src="/images/profile-pic.png"
          alt="Ruan Bekker"
          className="w-40 h-40 object-cover rounded-full mx-auto border-4 border-green-600 shadow-lg"
        />

        {/* Introduction */}
        <h2 className="text-2xl font-bold mt-4 text-green-700">Hi, I'm Ruan Bekker</h2>
        <p className="text-gray-600 mt-2">
          I am a passionate <span className="font-semibold">DevOps Engineer</span> who loves automation, infrastructure, and open source.
          When I'm not working with Kubernetes and Terraform, you'll find me <span className="font-semibold">on the golf course</span>, enjoying the game with friends.
        </p>

        {/* Hobbies Icons */}
        <div className="flex justify-center gap-4 mt-4">
          <FontAwesomeIcon icon={faLaptopCode} className="text-3xl text-blue-500" title="DevOps & Coding" />
          <FontAwesomeIcon icon={faGolfBallTee} className="text-3xl text-green-500" title="Golf Enthusiast" />
        </div>

        {/* Links */}
        <div className="mt-6 flex justify-center space-x-4">
          <a
            href="https://ruan.dev"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-700 text-white px-4 py-2 rounded-md hover:bg-green-800 flex items-center gap-2"
          >
            <FontAwesomeIcon icon={faGlobe} />
            My Website
          </a>
          <a
            href="https://twitter.com/ruanbekker"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gray-900 text-white px-4 py-2 rounded-md hover:bg-gray-700 flex items-center gap-2"
          >
            <FontAwesomeIcon icon={faXTwitter} />
            Twitter (X)
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;

